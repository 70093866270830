<template>
  <div class="form">
    <div class="form-title">
      <span>{{ $t("form_Q13") }}</span>
      <span class="danger-text">{{ $t("form_require") }}</span>
    </div>

    <section class="form-container">
      <!-- 名前 フリガナ -->
      <section class="form-row mt-3">
        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_name") }}</span>
            <span class="danger-text">
              {{ $t("form_require") }}
            </span>
          </div>
          <v-text-field
            outlined
            dense
            :label="$t('name_placeholder')"
            v-model="data.name"
            :rules="rules.name"
            @change="setData_name()"
          ></v-text-field>
        </div>

        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_kata") }}</span>
            <span class="danger-text">
              {{ $t("form_require") }}
            </span>
          </div>
          <v-text-field
            outlined
            dense
            :label="$t('kata_placeholder')"
            v-model="data.name_kata"
            :rules="rules.name_kata"
            @change="setData_name_kata()"
          ></v-text-field>
        </div>
      </section>

      <!--"地域 電話番号 メールアドレス"-->
      <section class="form-row">
        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_region") }}</span>
            <span class="danger-text">
              {{ $t("form_require") }}
            </span>
          </div>
          <v-select
            v-model="data.region"
            :items="lists.region"
            :label="$t('select_placeholder')"
            :rules="rules.region"
            outlined
            dense
            @change="setData_region()"
          ></v-select>
        </div>

        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_phone") }}</span>
            <span class="danger-text">
              {{ $t("form_require") }}
            </span>
          </div>
          <v-text-field
            outlined
            dense
            :label="$t('phone_placeholder')"
            v-model="data.phone"
            :rules="rules.phone"
            @change="setData_phone()"
          ></v-text-field>
        </div>

        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_email") }}</span>
          </div>
          <v-text-field
            outlined
            dense
            :label="$t('email_placeholder')"
            v-model="data.email"
            @change="setData_email()"
          ></v-text-field>
        </div>
      </section>

      <!--"年齢 性別 職業"-->
      <section class="form-row">
        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_age") }}</span>
          </div>
          <v-select
            v-model="data.age"
            :items="lists.age"
            :label="$t('select_placeholder')"
            outlined
            dense
            @change="setData_age()"
          ></v-select>
        </div>

        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_sex") }}</span>
          </div>
          <v-select
            v-model="data.sex"
            :items="lists.sex"
            :label="$t('select_placeholder')"
            outlined
            dense
            @change="setData_sex()"
          ></v-select>
        </div>

        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_occupation") }}</span>
          </div>
          <v-select
            v-model="data.occupation"
            :items="lists.occupation"
            :label="$t('select_placeholder')"
            outlined
            dense
            @change="setData_occupation()"
          ></v-select>
        </div>
      </section>

      <!--"見解書送付先"-->
      <section class="form-row">
        <div class="form-col">
          <div class="form-sub-title">
            <span>{{ $t("form_contact_forwarding") }}</span>
          </div>

          <section class="form-row">
            <v-text-field
              outlined
              dense
              :label="$t('forwarding_name_placeholder')"
              v-model="data.forwardingName"
              :rules="rules.forwardingName"
              @change="setData_forwardingName()"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              :label="$t('email_placeholder')"
              v-model="data.forwardingEmail"
              @change="setData_forwardingEmail()"
            ></v-text-field>
          </section>
        </div>
      </section>

      <!-- submit start -->
      <section class="form-send">
        <v-dialog v-model="confDialog" persistent max-width="800">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="form-send-button"
              @click="checkForm()"
              v-bind="attrs"
              v-on="on"
            >
              <span class="text_to_send">{{ $t("form_send") }}</span>
            </div>
          </template>

          <v-card>
            <div v-if="errorFlag">
              <v-card-title class="text-h5 pl-2">{{
                $t("error_label")
              }}</v-card-title>
              <div
                v-for="(item, index) in errorMessage"
                :key="index"
                class="pt-2 pl-2 danger-text"
              >
                {{ item }}
              </div>
            </div>

            <v-card-title v-else class="text-h5">{{
              $t("post_label")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="confDialog = false">
                {{ errorFlag ? $t("previos_btn_label") : $t("disagree") }}
              </v-btn>
              <v-btn v-if="!errorFlag" color="#132d7a" @click="sendAnswers()">
                <span style="color: #fff">{{ $t("agree") }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
          <div class="spiner" v-if="isSpiner">
            <v-progress-circular
              indeterminate
              color="green"
            ></v-progress-circular>
          </div>
        </v-dialog>
      </section>
    </section>
    <!-- submit end -->
  </div>
</template>

<script>
import { options1, age, sex, occupation } from "./answer";
import utility from "./utility";
import axios from "axios";

export default {
  name: "Form13",
  data() {
    return {
      confDialog: false,
      errorMessage: [],
      errorFlag: false,
      isSpiner: false,
      data: {
        name: "",
        name_kata: "",
        phone: "",
        email: "",
        region: "",
        age: "",
        sex: "",
        occupation: "",
        forwardingName: "",
        forwardingEmail: "",
      },
      rules: {
        name: [
          (v) => !!v || "入力してください",
          (v) => (v && v.length <= 30) || "30文字以内で入力してください",
        ],
        name_kata: [
          (v) => !!v || "入力してください",
          (v) => /^[ァ-ンヴー]+$/.test(v) || "全角カタカナで入力してください",
          (v) => (v && v.length <= 30) || "30文字以内で入力してください",
        ],
        phone: [
          (v) => !!v || "入力してください",
          (v) =>
            /^(0{1}\d{9,10})$/.test(v) || "電話番号の形式が正しくありません",
        ],
        email: [
          (v) =>
            /.+@.+\..+/.test(v) ||
            "メールアドレスの形式が正しくありません",
        ],
        forwardingName: [
          (v) => v.length <= 255 || "255文字以内で入力してください",
        ],
        forwardingEmail: [
          (v) =>
            /.+@.+\..+/.test(v) ||
            "メールアドレスのフォーマットが正しくありません",
        ],
        region: [(v) => !!v || "選択してください"],
      },
      lists: {
        region: options1,
        age: age,
        sex: sex,
        occupation: occupation,
      },
    };
  },
  mounted() {
    let sessionItem_name = sessionStorage.getItem("name");
    let sessionItem_name_kata = sessionStorage.getItem("name_kata");
    let sessionItem_phone = sessionStorage.getItem("phone");
    let sessionItem_email = sessionStorage.getItem("email");
    let sessionItem_region = sessionStorage.getItem("region");
    let sessionItem_age = sessionStorage.getItem("age");
    let sessionItem_sex = sessionStorage.getItem("sex");
    let sessionItem_occupation = sessionStorage.getItem("occupation");
    let sessionItem_forwardingName = sessionStorage.getItem("forwardingName");
    let sessionItem_forwardingEmail = sessionStorage.getItem("forwardingEmail");

    if (sessionItem_name) {
      this.data.name = sessionItem_name;
    }

    if (sessionItem_name_kata) {
      this.data.name_kata = sessionItem_name_kata;
    }

    if (sessionItem_phone) {
      this.data.phone = sessionItem_phone;
    }

    if (sessionItem_email) {
      this.data.email = sessionItem_email;
    }

    if (sessionItem_region) {
      this.data.region = sessionItem_region;
    }

    if (sessionItem_age) {
      this.data.age = sessionItem_age;
    }

    if (sessionItem_sex) {
      this.data.sex = sessionItem_sex;
    }

    if (sessionItem_occupation) {
      this.data.occupation = sessionItem_occupation;
    }
    if (sessionItem_forwardingName) {
      this.data.forwardingName = sessionItem_forwardingName;
    }
    if (sessionItem_forwardingEmail) {
      this.data.forwardingEmail = sessionItem_forwardingEmail;
    }
  },
  methods: {
    setData_name() {
      sessionStorage.setItem("name", this.data.name);
    },
    setData_name_kata() {
      sessionStorage.setItem("name_kata", this.data.name_kata);
    },
    setData_phone() {
      sessionStorage.setItem("phone", this.data.phone);
    },
    setData_email() {
      sessionStorage.setItem("email", this.data.email);
    },
    setData_region() {
      sessionStorage.setItem("region", this.data.region);
    },
    setData_age() {
      sessionStorage.setItem("age", this.data.age);
    },
    setData_sex() {
      sessionStorage.setItem("sex", this.data.sex);
    },
    setData_occupation() {
      sessionStorage.setItem("occupation", this.data.occupation);
    },
    setData_forwardingName() {
      sessionStorage.setItem("forwardingName", this.data.forwardingName);
    },
    setData_forwardingEmail() {
      sessionStorage.setItem("forwardingEmail", this.data.forwardingEmail);
    },
    checkForm() {
      this.confDialog = true;
      this.errorMessage = [];

      var checkResult = utility.checkValidation();
      for (var i = 0; i < checkResult.checkLists.length; i++) {
        if (checkResult.checkLists[i] === false) {
          this.errorMessage.push(checkResult.errorsMessage[i]);
        }
      }
      this.errorFlag = checkResult.checkLists.some(function(value) {
        return value === false;
      });
    },
    sendAnswers() {
      this.isSpiner = true;
      const obj = {
        app: "32",
        record: {
          q1: {
            value: JSON.parse(sessionStorage.getItem("q1")),
          },
          q2: {
            value: sessionStorage.getItem("q2"),
          },
          q3: {
            value: JSON.parse(sessionStorage.getItem("q3")),
          },
          q4: {
            value: JSON.parse(sessionStorage.getItem("q4")),
          },
          q5: {
            value: sessionStorage.getItem("q5"),
          },
          q6: {
            value: sessionStorage.getItem("q6"),
          },
          q7: {
            value: JSON.parse(sessionStorage.getItem("q7")),
          },
          q8: {
            value: JSON.parse(sessionStorage.getItem("q8")),
          },
          q9: {
            value: sessionStorage.getItem("q9"),
          },
          q10: {
            value: JSON.parse(sessionStorage.getItem("q10")),
          },
          q11: {
            value: sessionStorage.getItem("q11"),
          },
          q12: {
            value: sessionStorage.getItem("q12"),
          },
          zipcode: {
            value: sessionStorage.getItem("zipcode"),
          },
          address: {
            value: sessionStorage.getItem("address"),
          },
          q14: {
            value: sessionStorage.getItem("q14"),
          },
          q15: {
            value: sessionStorage.getItem("q15"),
          },
          q15_1: {
            value: sessionStorage.getItem("q15_1"),
          },
          name: {
            value: sessionStorage.getItem("name"),
          },
          name_kata: {
            value: sessionStorage.getItem("name_kata"),
          },
          phone: {
            value: sessionStorage.getItem("phone"),
          },
          email: {
            value: sessionStorage.getItem("email"),
          },
          region: {
            value: sessionStorage.getItem("region"),
          },
          age: {
            value: sessionStorage.getItem("age"),
          },
          sex: {
            value: sessionStorage.getItem("sex"),
          },
          occupation: {
            value: sessionStorage.getItem("occupation"),
          },
          forwardingName: {
            value: sessionStorage.getItem("forwardingName"),
          },
          forwardingEmail: {
            value: sessionStorage.getItem("forwardingEmail"),
          },
          login_email: {
            value: sessionStorage.getItem("login_email"),
          },
        },
      };
      var data = JSON.stringify(obj);
      const key = "consult";
      const url = `${this.$config.lambda_url}?key=${key}&data=${data}`;

      axios
        .get(url)
        .then(() => {
          this.$router.push("/send01");
          sessionStorage.clear();
          this.isSpiner = false;
        })
        .catch((error) => {
          this.isSpiner = false;
          alert("送信エラー: 管理者にお問い合わせください\n" + error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;

  .form-title {
    font-size: max(16px, 1.8vw);
    font-weight: bold;
    color: #132d7a;
    text-align: center;
  }

  .form-sub-title {
    font-size: max(14px, 1.2vw);
    font-weight: bold;
    margin-bottom: 10px;
  }

  .form-container {
    margin: 0 15%;
    .form-row {
      display: flex;
      gap: 20px;

      .form-col {
        flex-grow: 1;
        width: 30%;
      }
    }
  }

  .form-send {
    display: flex;
    justify-content: center;
    height: 40px;

    .form-send-button {
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 30%;
      background-color: #132d7a;
      border-radius: 15px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        opacity: 0.8;
      }

      .text_to_send {
        color: #fafafa;
        font-size: max(15px, 1.5vw);
        font-weight: bold;
        letter-spacing: 0.6rem;
      }
    }
  }
}
</style>
