const utility = {
  checkValidation() {
    const answers = [
      JSON.parse(sessionStorage.getItem("q1")), //[0]  *require
      sessionStorage.getItem("q2"), //[1]  *require
      JSON.parse(sessionStorage.getItem("q3")), //[2]  *require
      JSON.parse(sessionStorage.getItem("q4")), //[3]  *require
      sessionStorage.getItem("q5"), //[4]  *require
      sessionStorage.getItem("q6"), //[5]  *require
      sessionStorage.getItem("q15"), //[6]  *require
      sessionStorage.getItem("zipcode"), //[7]  *require
      sessionStorage.getItem("region"), //[8]  *require
      sessionStorage.getItem("q11"), //[9]  *require max:255
      sessionStorage.getItem("address"), //[10] *require max:255
      sessionStorage.getItem("q14"), //[11] *require max:255
      sessionStorage.getItem("q15_1"), //[12] *require max:255 && q15 == 'はい'
      sessionStorage.getItem("q12"), //[13] *require max:500
      sessionStorage.getItem("phone"), //[14] *require type:phoneNum
      sessionStorage.getItem("email"), //[15] type:mail
      sessionStorage.getItem("name"), //[16] *require max:30
      sessionStorage.getItem("name_kata"), //[17] *require max:30 type: kata
      sessionStorage.getItem("forwardingName"), //[18]
      sessionStorage.getItem("forwardingEmail"), //[19]
      JSON.parse(sessionStorage.getItem("q7")), //[20]
      JSON.parse(sessionStorage.getItem("q8")), //[21]
      sessionStorage.getItem("q9"), //[22]
      JSON.parse(sessionStorage.getItem("q10")), //[23]
      sessionStorage.getItem("age"), //[24]
      sessionStorage.getItem("sex"), //[25]
      sessionStorage.getItem("occupation"), //[26]
    ];
    //answersのindexに対応するエラーテキスト
    const errorsMessage = [
      "'Q1' に入力不備があります", //[0]
      "'Q2' に入力不備があります", //[1]
      "'Q3' に入力不備があります", //[2]
      "'Q4' に入力不備があります", //[3]
      "'Q5' に入力不備があります", //[4]
      "'Q6' に入力不備があります", //[5]
      "'Q12:土地と建物の名義人が異なりますか？' に入力不備があります", //[6]
      "'Q12:郵便番号' に入力不備があります", //[7]
      "'Q13:今お住まいの地域はどこですか？' に入力不備があります", //[8]
      "'Q11:不動産名義人氏名' に入力不備があります", //[9]
      "'Q12:住所' に入力不備があります", //[10]
      "'Q12:所有地の地番を教えてください' に入力不備があります", //[11]
      "'Q12:土地と建物の名義人が異なりますか？' に入力不備があります", //[12]
      "'Q11:名義人と相談者の関係' に入力不備があります", //[13]
      "'Q13:電話番号(ハイフン無し)' に入力不備があります", //[14]
      "'Q13:メールアドレス' に入力不備があります", //[15]
      "'Q13:お名前' に入力不備があります", //[16]
      "'Q13:フリガナ' に入力不備があります", //[17]
      "'Q13:送付先宛名'に入力不備があります", //[18]
      "'Q13:送付先メールアドレス'に入力不備があります", //[19]
      "", //[20]
      "", //[21]
      "", //[22]
      "", //[23]
      "", //[24]
    ];

    var checkLists = [];

    console.log("checkdate 20220423");
    for (var i = 0; i < answers.length; i++) {
      if (i < 9) {
        //[0]~[8] *require
        if (answers[i]) {
          checkLists[i] = true;
        } else {
          checkLists[i] = false;
        }
      } else if (8 < i && i < 11) {
        //[9]~[11] *require max:255
        if (answers[i] && answers[i].length <= 255) {
          checkLists[i] = true;
        } else {
          checkLists[i] = false;
        }
      } else if (i === 11) {
        //[9]~[11] *require max:255
        if (answers[i] && answers[i].length > 255) {
          checkLists[i] = false;
         } else {
          checkLists[i] = true;
         }
      } else if (i === 12) {
        //[12] *require max:255 && q15='はい'
        if (answers[6] === "はい") {
          if (answers[i] && answers[i].length <= 255) {
            checkLists[i] = true;
          } else {
            checkLists[i] = false;
          }
        } else {
          checkLists[i] = true;
        }
      } else if (i === 13) {
        //[13] *require max:500
        if (answers[i] && answers[i].length <= 500) {
          checkLists[i] = true;
        } else {
          checkLists[i] = false;
        }
      } else if (i === 14) {
        //[14] *require type:phoneNum
        if (
          answers[i] &&
          answers[i].match(/^(0{1}\d{9,10})$/) &&
          answers[i].length <= 15
        ) {
          checkLists[i] = true;
        } else {
          checkLists[i] = false;
        }
      } else if (i === 15) {
        //[15] *require type:mail
        if (answers[i]) {
          if (answers[i].match(/.+@.+\..+/) && answers[i].length <= 255) {
            checkLists[i] = true;
          } else {
            checkLists[i] = false;
          }
        } else if (answers[18] || answers[19]) {
          checkLists[i] = true;
        } else {
          checkLists[i] = false;
        }
      } else if (i === 16) {
        //[16] *require max:30
        if (answers[i] && answers[i].length <= 255) {
          checkLists[i] = true;
        } else {
          checkLists[i] = false;
        }
      } else if (i === 17) {
        //[17] *require max:30 type: kata
        if (
          answers[i] &&
          answers[i].length <= 30 &&
          answers[i].match(/^[ァ-ンヴー]+$/)
        ) {
          checkLists[i] = true;
        } else {
          checkLists[i] = false;
        }
      } else if (i === 18) {
        if (answers[i]) {
          //宛名あり
          if (answers[i].length <= 255) {
            //宛名バリデーション
            if (answers[19]) {
              checkLists[i] = true;
            } else {
              checkLists[19] = false;
            }
          } else {
            checkLists[i] = false;
          }
        } else if (!answers[19]) {
          //宛名なし 送付emailなし エラーなし
          checkLists[i] = true;
        } else {
          checkLists[i] = false; //宛名なし 送付emailあり エラーあり
        }
      } else if (i === 19) {
        if (answers[i]) {
          if (answers[i].match(/.+@.+\..+/) && answers[i].length <= 255) {
            if (answers[18]) {
              checkLists[i] = true;
            } else {
              checkLists[18] = false;
            }
          } else {
            checkLists[i] = false;
          }
        } else if (!answers[18]) {
          checkLists[i] = true;
        } else {
          checkLists[i] = false;
        }
      } else {
        //[20]~[26] no validation
        checkLists[i] = true;
      }
    }
    var result = {
      checkLists: checkLists,
      errorsMessage: errorsMessage,
    };
    return result;
  },
};

export default utility;
